<template>
    <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="mapping(data)">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Faq Video</h5>
          <span aria-hidden="true" role="button" @click="close">&times;</span>
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Name</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="How to kill mobs" v-model="faq.name">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Answer</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Thinking of you. Thinking of you" v-model="faq.answer">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Upload Image</label>
                    <FileManager :field="'path'" :object="faq"></FileManager>
                </div>
            </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" @click="getFaq(faq); close();">Save</button>
          <button type="button" class="btn btn-danger" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import FileManager from "node-back-client-input-extension/src/components/FileManager";
export default{
    name : "FaqModal",
    props: {
        data : Object,
        getFaq : Function
    },
    data(){
        return {faq : {}}
    },
    components:{
        FileManager
    },
    methods : {
        close(){
            this.faq = {};
            let data = this.data;
            data.show = false;
        },
        mapping(faq){
            this.faq = faq;
            return this.data.show;
        }
    }
}
</script>


<style scoped>
.modal{
    display: block;
}
</style>