<template>
  <div>
    <h1>Faq Videos Uploader</h1>

   <DataTable
                :headers="headers"
                :actions="actions"
                :api="getFaq"
                :fields="['name']"
  />

  <FaqModal :data="data" :getFaq="postFaq" v-if="data.show"/>
  <button type="button" class="btn btn-info" style="color:white; float:right" @click="data.show = !data.show">Add</button>
  </div>
</template>

<script>
import DataTable from 'data-table/src/components/DataTable2'
import store from 'node-back-client/src/store'
import FaqModal from '../components/FaqModal.vue';
import ToastService from 'toast/src/services/toast'

export default {
  name: 'Faq',
  data(){
    return {store : store, faqList : [], faq : {}, trigger: {}, data : {show : false},
    headers: {
        id : "ID",
        name: "Name",
        path: "Path"
      },
      actions: [
        {
          classNames:"fa fa-trash text-danger",
          callback: this.remove
        },
        {
          classNames:"fa fa-edit text-info",
          callback: this.edit
        }
      ]
    }
  },
  components : {
    DataTable,
    FaqModal
},
  methods : {
    async getFaq(){
      return await store.custom.apis["region"].getVideo(...arguments);
    },
    async postFaq(data){

      try {
        // if(!data.path || !data.name || !data.answer){
        if(!data.name){
          throw new Error().message = "Can't upload with missing fields";
        }
        data._id = data.id
        // data.id = data._id;
        await store.custom.apis["region"].uploadFile(data);

      } catch (error) {
        ToastService.Notify({
          title: "Error",
          message: error,
          imgSrc: "/favicon.ico"
        });
      }
    },
    async remove(faq){
      await store.custom.apis["region"].deleteVideo(faq)
      await this.getFaq();
    },
    async edit(faq){
      faq.show = true;
      this.data = faq;
    }
  },
  created() {
    this.getFaq();
  }
}
</script>
